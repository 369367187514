import React, { useState, useEffect } from 'react';
import PresupuestoService from "../services/PresupuestoService";
import { useParams } from "react-router-dom";

const PresupuestoDoc = () => {
    const { id } = useParams();
    const [presupuesto, setPresupuesto] = useState(null);

    useEffect(() => {
        if (id) {
            PresupuestoService.verPresupuestoDoc(id)
                .then(response => {
                    setPresupuesto(response.data);
                })
                .catch(error => {
                    console.error('Error fetching presupuesto:', error);
                });
        }
    }, [id]);

    if (presupuesto === null) return "Presupuesto no disponible";

    const styles = {
        container: {
            fontFamily: 'Roboto, sans-serif',
            color: '#4472c4',
            minHeight: '29.7cm', // Altura A4
            padding: '2cm', // Espaciado interno
            display: 'flex',
            flexDirection: 'column',
        },
        content: {
            flex: 1,
            marginBottom: '20px', // Espaciado inferior del contenido
        },
        paragraph: {
            fontWeight: 300,
            fontSize: '16px',
            margin: '5px 0',
        },
        heading: {
            fontWeight: 400,
            fontSize: '20px',
            marginBottom: '5px',
            borderBottom: '1px solid #d9d9d9', // Línea divisoria
            paddingBottom: '5px',
        },
        mainHeading: {
            fontWeight: 400,
            fontSize: '36px',
            textAlign: 'left',
            marginBottom: '20px',
            marginLeft: '25px',
        },
        clientInfo: {
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '20px',
        },
        column: {
            flex: 1,
            padding: '0 10px',
        },
        businessName: {
            fontWeight: 300,
            fontSize: '18px',
            margin: '5px 0',
        },
        footerTitle: {
            fontWeight: 300,
            fontSize: '26px',
            marginBottom: '-10px',
        },
        footerText: {
            fontWeight: 300,
            fontSize: '13px',
            marginBottom: '-12px',
        },
        image: {
            width: '200px',
            height: 'auto',
            display: 'block',
            margin: '0 auto 10px', // Centra la imagen
        },
        footer: {
            marginTop: 'auto',
            textAlign: 'center',
            borderTop: '1px solid #d9d9d9',
            paddingTop: '10px',
        },
        footerImage: {
            width: '100px',
            height: 'auto',
            margin: '10px auto',
        },
    };

    return (
        <div style={styles.container}>
            <link href="https://fonts.googleapis.com/css2?family=Roboto:wght@300;400&display=swap" rel="stylesheet" />

            <div style={styles.content}>
                <img src={`${process.env.PUBLIC_URL}/logo.svg`} alt="Reefers del sur" style={styles.image} />
                <h2 style={styles.mainHeading}>COTIZACIÓN</h2>

                <div style={styles.clientInfo}>
                    <div style={styles.column}>
                        <p style={styles.paragraph}><strong>Fecha:</strong> {presupuesto.fechaFormatted}</p>
                        <p style={styles.paragraph}><strong>Cliente:</strong> {presupuesto.cliente.nombre}</p>
                        <p style={styles.paragraph}><strong>Email:</strong> {presupuesto.cliente.email}</p>
                    </div>
                    <div style={styles.column}>
                        <p style={styles.businessName}><strong>Empresa:</strong> {presupuesto.cliente.empresa.razonSocial}</p>
                        <p style={styles.paragraph}><strong>CUIT/CDI:</strong> {presupuesto.cliente.empresa.cuit}</p>
                        <p style={styles.paragraph}><strong>Domicilio:</strong> {presupuesto.cliente.empresa.direccion}</p>
                    </div>
                </div>

                <h3 style={styles.heading}>Servicio</h3>
                <p style={styles.paragraph}>{presupuesto.servicio}</p>

                <h3 style={styles.heading}>Transporte</h3>
                <p style={styles.paragraph}>{presupuesto.transporte}</p>

                <h3 style={styles.heading}>Plazo de entrega</h3>
                <p style={styles.paragraph}>{presupuesto.plazoDeEntrega}</p>

                <h3 style={styles.heading}>Valor total</h3>
                <p style={styles.paragraph}>AR {presupuesto.valorFormatted} (pesos argentinos {presupuesto.valorTextoFormatted}) + impuestos, factura C.</p>
            </div>

            <footer style={styles.footer}>
                <p style={styles.footerTitle}>Reefers del Sur</p>
                <p style={styles.footerText}>De Carlos y Juan Miguez.</p>
                <p style={styles.footerText}>Av. Velez Sarsfield 865.</p>
                <p style={styles.footerText}>Email: <a href="mailto:contacto@reefersdelsur.com">contacto@reefersdelsur.com</a></p>
                <p style={styles.footerText}>Tel: <a href="tel:+54 9 11 5837-9669">+54 9 11 5837-9669</a></p>
                <img src={`${process.env.PUBLIC_URL}/logo.svg`} alt="Footer de Reefers del Sur" style={styles.footerImage} />
            </footer>
        </div>
    );
}

export default PresupuestoDoc;
