import React, { useState, useEffect } from 'react';
import AlquilerService from "../services/AlquilerService";
import { useParams } from "react-router-dom";

const ContratoDoc = () => {
    const { id, idContrato } = useParams();
    const [contrato, setContrato] = useState(null);

    useEffect(() => {
        if (id && idContrato) {
            AlquilerService.verContrato(id, idContrato)
                .then(response => {
                    setContrato(response.data);
                })
                .catch(error => {
                    console.error('Error al obtener Contrato:', error);
                });
        }
    }, [id, idContrato]);

    if (contrato === null) return "Contrato no disponible";

    const styles = {
        container: {
            fontFamily: 'Roboto Mono, monospace',
            color: '#4472c4',
            minHeight: '29.7cm',
            paddingRight: '0.5cm',
            paddingLeft: '0.5cm',
            paddingTop: '1.5cm',
            paddingBottom: '1cm',
            display: 'flex',
            flexDirection: 'column',
            position: 'relative', // Permite posicionar el logo
        },
        logo: {
            position: 'absolute',
            top: '10px',
            right: '10px',
        },
        contenido: {
            fontFamily: 'Roboto Mono, monospace',
            fontSize: "13px",
            textAlign: "justify",
            lineHeight: "1.6",
            margin: "10px 0",
        },
        footer: {
            display: "flex",
            fontSize: "14px",
            justifyContent: "space-between",
            padding: "20px 0",
        },
        column: {
            flex: "1",
            textAlign: "center",
        },
    };

    return (
        <div style={styles.container}>
            <link href="https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@300&display=swap" rel="stylesheet"/>
            <img
                src={`${process.env.PUBLIC_URL}/logo.svg`}
                alt="Reefers del Sur"
                width="80"
                style={styles.logo}
            />
            <section style={styles.contenido}>
                {contrato.contenido}
            </section>
            <footer style={styles.footer}>
                <div style={styles.column}>
                    <p>{contrato.alquiler.cliente.nombre}</p>
                    <p>CUIT: {contrato.alquiler.cliente.cuit}</p>
                    <p>Locatario</p>
                    <p>___________________</p>
                </div>
                <div style={styles.column}>
                    <p>............................</p>
                    <p>CUIT: ......................</p>
                    <p>Garante</p>
                    <p>___________________</p>
                </div>
                <div style={styles.column}>
                    <p>{contrato.alquiler.emisor.razonSocial}</p>
                    <p>CUIT: {contrato.alquiler.emisor.cuit}</p>
                    <p>Locador</p>
                    <p>___________________</p>
                </div>
            </footer>
        </div>
    );
}

export default ContratoDoc;
