import React, { useState, useEffect } from 'react';
import ModeloContratoService from '../services/ModeloContratoService';
import { useParams, useNavigate } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const ModeloContratoForm = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [modeloContrato, setModeloContrato] = useState({ titulo: '', contenido: '' });

    useEffect(() => {
        if (id) {
            ModeloContratoService.obtenerPorId(id).then(response => {
                setModeloContrato(response.data);
            }).catch(error => {
                console.error('Error al obtener el modelo de contrato:', error);
            });
        }
    }, [id]);

    const handleSubmit = (event) => {
        event.preventDefault();

        if (id) {
            ModeloContratoService.actualizar(id, modeloContrato).then(() => {
                navigate('/modelosContrato');
            }).catch(error => {
                console.error('Error al crear el modelo:', error);
            });
        } else {
            ModeloContratoService.guardar(modeloContrato).then(() => {
                navigate('/modelosContrato');
            }).catch(error => {
                console.error('Error al guardar el modelo:', error);
            });
        }
    };

    const handleContentChange = (content) => {
        setModeloContrato({ ...modeloContrato, contenido: content });
    };

    return (
        <div>
            <h2>{id ? 'Editar modelo de contrato' : 'Crear modelo de contrato'}</h2>
            <form onSubmit={handleSubmit}>
                <div>
                    <label>Título:</label>
                    <input
                        type="text"
                        value={modeloContrato.titulo}
                        onChange={e => setModeloContrato({ ...modeloContrato, titulo: e.target.value })}
                    />
                </div>
                <div>
                    <label>Contrato:</label>
                    <textarea
                        value={modeloContrato.contenido}
                        onChange={handleContentChange}  // Aquí es donde agregas la función para manejar cambios
                    />
                </div>
                <button type="submit">Guardar</button>
            </form>
        </div>
    );
};

export default ModeloContratoForm;
