import React, { useState, useEffect } from 'react';
import ReporteService from '../services/ReporteService';
import ContenedorService from '../services/ContenedorService';
import { useParams, useNavigate } from 'react-router-dom';

const ReporteForm = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const [reporte, setReporte] = useState({
        contenedorId: '',
        descripcion: '',
        fechaReporte: '',
        resuelto: false
    });

    const [contenedores, setContenedores] = useState([]);

    useEffect(() => {
        // Cargar lista de contenedores
        ContenedorService.obtenerTodos().then(response => {
            setContenedores(response.data);
        }).catch(error => {
            console.error('Error fetching containers:', error);
        });

        // Si existe un ID, cargar el reporte para editarlo
        if (id) {
            ReporteService.obtenerPorId(id).then(response => {
                setReporte({
                    contenedorId: response.data.contenedor.id,
                    descripcion: response.data.descripcion,
                    fechaReporte: response.data.fechaReporte,
                    resuelto: response.data.resuelto
                });
            }).catch(error => {
                console.error('Error fetching reporte:', error);
            });
        }else{

        }
    }, [id]);

    const handleSubmit = (event) => {
        event.preventDefault();
        const reporteData = {
            contenedor: { id: reporte.contenedorId },
            descripcion: reporte.descripcion,
            fechaReporte: reporte.fechaReporte,
            resuelto: reporte.resuelto
        };

        if(id){
            ReporteService.actualizar(id, reporteData).then(() => {
                navigate('/reportes');
            }).catch(error => {
                console.error('Error saving reporte:', error);
            });
        }else{
            ReporteService.guardar(reporteData).then(() => {
                navigate('/reportes');
            }).catch(error => {
                console.error('Error saving reporte:', error);
            });
        }


    }

    const handleVerContenedor = () => {
        if (reporte.contenedorId) {
            navigate(`/contenedores/${reporte.contenedorId}`);
        } else {
            alert('Por favor, seleccione un contenedor primero.');
        }
    };

    return (
        <div>
            <h2>{id ? 'Editar Reporte' : 'Crear Reporte'}</h2>
            <form onSubmit={handleSubmit}>
                <div>
                    <label>Contenedor:</label>
                    <select
                        value={reporte.contenedorId}
                        onChange={e => setReporte({...reporte, contenedorId: e.target.value})}
                    >
                        <option value="">Seleccione un Contenedor</option>
                        {contenedores.map(contenedor => (
                            <option key={contenedor.id} value={contenedor.id}>
                                {contenedor.codigo}
                            </option>
                        ))}
                    </select>
                    <button type="button" onClick={handleVerContenedor}>Ver</button>
                </div>
                <div>
                    <label>Descripción:</label>
                    <textarea
                        value={reporte.descripcion}
                        onChange={e => setReporte({...reporte, descripcion: e.target.value})}
                    />
                </div>
                <div>
                    <label>Fecha del Reporte:</label>
                    <input
                        type="date"
                        value={reporte.fechaReporte}
                        onChange={e => setReporte({...reporte, fechaReporte: e.target.value})}
                    />
                </div>
                <div>
                    <label>Resuelto:</label>
                    <input
                        type="checkbox"
                        checked={reporte.resuelto}
                        onChange={e => setReporte({...reporte, resuelto: e.target.checked})}
                    />
                </div>
                <button type="submit">Guardar</button>
            </form>
        </div>
    );
}

export default ReporteForm;
