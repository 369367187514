// src/components/LandingPage.js
import React from 'react';

function LandingPage() {
  return (
    <div>
      <h1>Bienvenido a la Landing Page</h1>
    </div>
  );
}

export default LandingPage;
