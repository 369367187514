import React, {useState, useEffect} from 'react';
import ContenedorService from '../services/ContenedorService';
import {useParams, useNavigate} from 'react-router-dom';
import EmisorService from "../services/EmisorService";

const ContenedorForm = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    const [reportes, setReportes] = useState([]);
    const [contenedor, setContenedor] = useState({
        codigo: '',
        tipo: '',
        clase: '',
        longitud: '',
        descripcion: '',
        disponible: true
    });

    useEffect(() => {
        if (id) {
            ContenedorService.obtenerPorId(id)
                .then(response => {
                    setContenedor(response.data);
                })
                .catch(error => {
                    console.error('Error fetching contenedor:', error);
                });

            // Cargar lista de reportes
            ContenedorService.getReportes(id).then(response => {
                setReportes(response.data);
            }).catch(error => {
                console.error('Error fetching containers:', error);
            });
        }

    }, [id]);

    const handleSubmit = (event) => {
        event.preventDefault();

        if (id) {
            ContenedorService.actualizar(id, contenedor)
                .then(() => {
                    navigate('/contenedores');
                })
                .catch(error => {
                    console.error('Error al guardar contenedor:', error);
                });
        } else {
            ContenedorService.guardar(contenedor)
                .then(() => {
                    navigate('/contenedores');
                })
                .catch(error => {
                    console.error('Error al crear contenedor:', error);
                });
        }
    };

    const handleNuevoReporte = () => {
        if (id) {
            navigate(`/reportes/nuevo`);
        }

    };

    const handleDelete = () => {
        if (window.confirm('¿Estás seguro de que deseas eliminar este contenedor?')) {
            EmisorService.eliminar(id).then(() => {
                navigate('/contenedores');
            }).catch(error => {
                console.error('Error al eliminar el contenedor:', error);
            });
        }
    }

    const tipos = ["REEFER", "SECO"];
    const clases = ["HIGH_CUBE", "LOW_CUBE", "OTRO"];
    const longitudes = ["VEINTE_PIES", "CUARENTA_PIES", "OTRO"];

    return (
        <div>
            <h2>{id ? 'Editar Contenedor' : 'Crear Contenedor'}</h2>
            <form onSubmit={handleSubmit}>
                <div>
                    <label>Código:</label>
                    <input
                        type="text"
                        value={contenedor.codigo}
                        onChange={e => setContenedor({...contenedor, codigo: e.target.value})}
                    />
                </div>
                <div>
                    <label>Tipo:</label>
                    <select
                        value={contenedor.tipo}
                        onChange={e => setContenedor({...contenedor, tipo: e.target.value})}
                    >
                        {tipos.map((tipo, index) => (
                            <option key={index} value={tipo}>
                                {tipo}
                            </option>
                        ))}
                    </select>
                </div>
                <div>
                    <label>Clase:</label>
                    <select
                        value={contenedor.clase}
                        onChange={e => setContenedor({...contenedor, clase: e.target.value})}
                    >
                        {clases.map((clase, index) => (
                            <option key={index} value={clase}>
                                {clase}
                            </option>
                        ))}
                    </select>
                </div>
                <div>
                    <label>Longitud:</label>
                    <select
                        value={contenedor.longitud}
                        onChange={e => setContenedor({...contenedor, longitud: e.target.value})}
                    >
                        {longitudes.map((longitud, index) => (
                            <option key={index} value={longitud}>
                                {longitud}
                            </option>
                        ))}
                    </select>
                </div>
                <div>
                    <label>Descripción:</label>
                    <textarea
                        value={contenedor.descripcion || ''}
                        onChange={e => setContenedor({...contenedor, descripcion: e.target.value})}
                    />
                </div>
                <div>
                    <label>Disponible:</label>
                    <input
                        type="checkbox"
                        checked={contenedor.disponible}
                        onChange={e => setContenedor({...contenedor, disponible: e.target.checked})}
                    />
                </div>
                <button type="submit">Guardar</button>
                {id && (
                    <button type="button" onClick={handleDelete} style={{ backgroundColor: 'red', color: 'white' }}>
                        Eliminar
                    </button>
                )}
            </form>
            <button onClick={() => navigate('/contenedores')}>Volver</button>



            {id && (
                <div>
                    <h3>Reportes <button type="button" onClick={handleNuevoReporte}>Crear</button></h3>
                    <ul>
                        {reportes.map(reporte => (
                            <li key={reporte.id}>
                                <h5>{reporte.fechaReporte} {reporte.resuelto}</h5>
                                <p>{reporte.descripcion}</p>
                            </li>
                        ))}
                    </ul>

                </div>
            )}
        </div>
    );
};

export default ContenedorForm;
