// src/components/HomePage.js
import React from 'react';

function HomePage() {
  return (
    <div>
      <h1>Bienvenido a la Página de Inicio</h1>
      <p>Has iniciado sesión correctamente.</p>
    </div>
  );
}

export default HomePage;
