// src/utils/axiosInstance.js
import axios from 'axios';
import AuthService from '../services/AuthService'; // Importa la instancia de AuthService
import config from './config';

const axiosInstance = axios.create({
  baseURL: config.API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Interceptor para agregar el token a cada solicitud si está disponible
axiosInstance.interceptors.request.use(
  (config) => {
    const token = AuthService.getToken(); // Obtén el token desde AuthService
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export default axiosInstance;
