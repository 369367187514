// src/components/layout/Navbar.js
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import AuthService from '../../services/AuthService';

const Navbar = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    AuthService.logout();
    navigate('/login'); // Redirige al login
  };

  return (
    <nav>
      <h2>Mi Aplicación</h2>
      <ul>
        <li><Link to="/home">Inicio</Link></li>
        <li><Link to="/modelosContrato">Modelos de contrato</Link></li>
        <li><Link to="/alquileres">Alquileres</Link></li>
        <li><Link to="/clientes">Clientes</Link></li>
        <li><Link to="/empresas">Empresas</Link></li>
        <li><Link to="/cobranzas">Cobranzas</Link></li>
        <li><Link to="/contenedores">Contenedores</Link></li>
        <li><Link to="/facturas">Facturas</Link></li>
        <li><Link to="/emisores">Emisores</Link></li>
        <li><Link to="/reportes">Reportes</Link></li>
        <li><Link to="/presupuestos">Presupuestos</Link></li>
      </ul>
      <button onClick={handleLogout}>Cerrar Sesión</button>
    </nav>
  );
};

export default Navbar;
