import React, { useState, useEffect } from 'react';
import CobranzaService from '../services/CobranzaService';
import { useParams, useNavigate } from 'react-router-dom';
import FacturaService from "../services/FacturaService";

const CobranzaForm = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [cobranza, setCobranza] = useState({ facturaId: '', fechaPago: '', montoPagado: '' });
    const [facturas, setFacturas] = useState([]);

    useEffect(() => {

        FacturaService.obtenerTodos().then(response => {
            setFacturas(response.data);
        }).catch(error => {
            console.error('Error fetching clients:', error);
        });

        if (id) {
            CobranzaService.obtenerPorId(id).then(response => {
                setCobranza({
                    facturaId: response.data.factura.id,
                    fechaPago: response.data.fechaPago,
                    montoPagado: response.data.montoPagado,
                });
            }).catch(error => {
                console.error('Error fetching cobranza:', error);
            });
        }
    }, [id]);

    const handleSubmit = (event) => {
        event.preventDefault();
        const cobranzaData = {
            factura: { id: cobranza.facturaId },
            montoPagado: cobranza.montoPagado,
            fechaPago: cobranza.fechaPago,
        };
        if(id){
            CobranzaService.actualizar(id, cobranzaData).then(() => {
                navigate('/cobranzas');
            }).catch(error => {
                console.error('Error al guardar cobranza:', error);
            });
        }else{
            CobranzaService.guardar(cobranzaData).then(() => {
                navigate('/cobranzas');
            }).catch(error => {
                console.error('Error al crear cobranza:', error);
            });
        }
    }

    const handleVerFactura = () => {
        if (cobranza.facturaId) {
            navigate(`/contenedores/${cobranza.facturaId}`);
        } else {
            alert('Por favor, seleccione una factura primero.');
        }
    };

    return (
        <div>
            <h2>{id ? 'Editar Cobranza' : 'Crear Cobranza'}</h2>
            <form onSubmit={handleSubmit}>
                <div>
                    <label>Monto:</label>
                    <input
                        type="number"
                        value={cobranza.montoPagado}
                        onChange={e => setCobranza({...cobranza, montoPagado: e.target.value})}
                    />
                </div>
                <div>
                    <label>Fecha:</label>
                    <input
                        type="date"
                        value={cobranza.fechaPago}
                        onChange={e => setCobranza({...cobranza, fechaPago: e.target.value})}
                    />
                </div>
                <div>
                    <label>Factura:</label>
                    <select
                        value={cobranza.facturaId}
                        onChange={e => setCobranza({...cobranza, facturaId: e.target.value})}
                    >
                        <option value="">Seleccione factura</option>
                        {facturas.map(factura => (
                            <option key={factura.id} value={factura.id}>
                                {factura.fechaEmision} - {factura.monto}
                            </option>
                        ))}
                    </select>
                    <button type="button" onClick={handleVerFactura}>Ver</button>
                </div>
                <button type="submit">Guardar</button>
            </form>
        </div>
    );
}

export default CobranzaForm;
