import React, { useState, useEffect } from 'react';
import EmisorService from '../services/EmisorService';
import { Link } from 'react-router-dom';

const EmisorList = () => {
    const [emisores, setEmisores] = useState([]);

    useEffect(() => {
        EmisorService.obtenerTodos().then(response => {
            setEmisores(response.data);
        }).catch(error => {
            console.error('Error fetching emisores:', error);
        });
    }, []);

    return (
        <div>
            <h2>Lista de emisores de factura</h2>
            <ul>
                {emisores.map(emisor => (
                    <li key={emisor.id}>

                        <Link to={`/emisores/${emisor.id}`}>
                            {emisor.razonSocial}
                        </Link>
                    </li>
                ))}
            </ul>
            <Link to="/emisores/nuevo"><button>Crear</button></Link>
        </div>
    );
}

export default EmisorList;
