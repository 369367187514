import React, { useState, useEffect } from 'react';
import AlquilerService from '../services/AlquilerService';
import {Link} from "react-router-dom";

const AlquilerList = () => {
    const [alquileres, setAlquileres] = useState([]);

    useEffect(() => {
        AlquilerService.obtenerTodos().then(response => {
            setAlquileres(response.data);
        }).catch(error => {
            console.error('Error fetching alquileres:', error);
        });
    }, []);

    return (
        <div>
            <h2>Lista de Alquileres</h2>
            <ul>
                {alquileres.map(alquiler => (
                    <li key={alquiler.id}>
                        <Link to={`/alquileres/${alquiler.id}`}>
                            {alquiler.cliente.nombre} - {alquiler.contenedor.codigo}
                        </Link>
                    </li>
                ))}
            </ul>
            <Link to="/alquileres/nuevo">
                <button>Crear</button>
            </Link>
        </div>
    );
}

export default AlquilerList;
