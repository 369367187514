import React, {useState, useEffect} from 'react';
import FacturaService from "../services/FacturaService";
import {useParams} from "react-router-dom";

const FacturaCDoc = () => {
    const {id} = useParams();
    const [factura, setFactura] = useState(null);

    useEffect(() => {
        if (id) {
            FacturaService.verFacturaCDoc(id)
                .then(response => {
                    setFactura(response.data);
                })
                .catch(error => {
                    console.error('Error fetching factura:', error);
                });
        }
    }, [id]);

    if (factura === null) return "Factura no disponible";

    return (
        <section className="py-3 py-md-5">
            <link rel="stylesheet" href="https://unpkg.com/bootstrap@5.3.3/dist/css/bootstrap.min.css"/>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-lg-9 col-xl-8 col-xxl-7">
                        <div className="row gy-3 mb-3">
                            <div className="row gy-3 mb-3">
                                <div
                                    className="col-6 d-flex align-items-center">
                                    <h1 className="text-uppercase text-start m-0">Factura C</h1>
                                </div>
                                <div
                                    className="col-6 d-flex justify-content-end"> {/* Añadir d-flex y justify-content-end */}
                                    <a className="d-block" href="https://www.reefersdelsur.com/">
                                        <img src={`${process.env.PUBLIC_URL}/logo.svg`} className="img-fluid"
                                             alt="Reefers del Sur"
                                             width="141" height="47"/>
                                    </a>
                                </div>
                            </div>

                            <div className="col-12">
                                <address>
                                    <strong>{factura.razonSocialFacturador}</strong><br/>
                                    {factura.domicilioComercialFacturador}<br/>
                                    CUIT: {factura.cuitFacturador}<br/>
                                    Condición IVA: {factura.condicionFrenteIvaFacturador}<br/>
                                </address>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-12 col-sm-6 col-md-6">
                                <address>
                                    <strong>{factura.razonSocialCliente}</strong><br/>
                                    {factura.direccionCliente}<br/>
                                    CUIT: {factura.cuitCliente}<br/>
                                    Condición IVA: {factura.condicionIvaCliente}<br/>
                                </address>
                            </div>
                            <div className="col-12 col-sm-6 col-md-6">
                                <h4 className="row">
                                    <span className="col-8">N° Comprobante</span>
                                    <span className="col-4 text-sm-end">{factura.nroComprobante}</span>
                                </h4>
                                <div className="row">
                                    <span className="col-6">Punto de venta</span>
                                    <span className="col-6 text-sm-end">{factura.puntoVenta}</span>
                                    <span className="col-6">Fecha de emisión</span>
                                    <span className="col-6 text-sm-end">{factura.formattedFechaEmision}</span>
                                    <span className="col-6">Vencimiento</span>
                                    <span className="col-6 text-sm-end">{factura.formattedVencimiento}</span>
                                    <span className="col-6">CAE</span>
                                    <span className="col-6 text-sm-end">{factura.cae}</span>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-12">
                                <div className="table-responsive">
                                    <table className="table table-striped">
                                        <thead>
                                        <tr>
                                            <th scope="col" className="text-uppercase">Descripción</th>
                                            <th scope="col" className="text-uppercase">Cantidad</th>
                                            <th scope="col" className="text-uppercase text-end">Precio unitario</th>
                                        </tr>
                                        </thead>
                                        <tbody className="table-group-divider">
                                        <tr>
                                            <td>Alquiler de contenedor, código: "{factura.codigoReefer}".</td>
                                            <td>1</td>
                                            <td className="text-end">$ {factura.importeNetoFormatted}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-6">
                                <div className="table-responsive">

                                    <table className="table table-striped">
                                        <thead>
                                        <tr>
                                            <th scope="col" className="text-uppercase">Otros tributos</th>
                                            <th scope="col" className="text-uppercase">Alíc. %</th>
                                            <th scope="col" className="text-uppercase text-end">Importe</th>
                                        </tr>
                                        </thead>
                                        <tbody className="table-group-divider">
                                        <tr>
                                            <td>IIBB</td>
                                            <td>3,50%</td>
                                            <td className="text-end">$ {factura.importeTributoFormatted}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="col-1">
                            </div>
                            <div className="col-5">
                                <table className="table">
                                    <tbody>
                                    <tr>&nbsp;</tr>
                                    <tr>&nbsp;</tr>
                                    <tr>&nbsp;</tr>
                                    <tr>&nbsp;</tr>
                                    <tr>
                                        <td>
                                            <div className="row">
                                                <span className="col-6">Subtotal: </span>
                                                <span
                                                    className="col-6 text-sm-end">$ {factura.importeNetoFormatted}</span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="row">
                                                <span className="col-6">Importe otros tributos: </span>
                                                <span
                                                    className="col-6 text-sm-end">$ {factura.importeTributoFormatted}</span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="row">
                                                <span className="col-6">Total: </span>
                                                <span
                                                    className="col-6 text-sm-end">$ {factura.importeTotalFormatted}</span>
                                            </div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-12">
                                <p>{factura.comentarios}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default FacturaCDoc;
